import { css } from '@emotion/react'
import { ButtonDegrade } from '@findep/mf-landings-core'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {
    Grid,
    Typography,
    CircularProgress,
    Backdrop,
    Snackbar
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CarouselImg from '../img/CarouselImg.compilable'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Oferta } from '@findep/mf-landings-core';
import formatNumber from '../../helpers/formatNumber'
import { CataloguesService } from '../../services/LandingV4'
import { OfferService } from '../../services/LandingV4/offer'
import NameCompany from '../../helpers/NameCompany'
import { getPeriodPlural } from '../../helpers/period'
import { navigate } from '../../helpers/queryNavigate'
import CONSTANTS from '../../helpers/preOfertaConstants'
import {
    useTheme
} from "@findep/microfronts-core"


const catalogos = new CataloguesService()
let Offer = []

function PreOfertaPrimerChamba({ pageContext }) {
    const theme = useTheme();
    const colorOne = theme.palette.primary.main || '#FF9F01';
    const colorTwo = theme.palette.secondary.main || '#FA4616';

    const [state, setState] = useState({
        datos: {
            amount: 0,
            estimatedPayment: 0,
            period: "Biweekly",
            terms: 0,
            branch: {
                name: "Nombre de la sucursal",
                address: "Dirección de la sucursal",
                neighborhood: "Colonia de la sucursal",
                postalCode: "12345",
                city: "Ciudad de la sucursal",
                state: "Estado de la sucursal"
            },
            offerId: ''
        },
        loading: false,
        buttonEnabled: true,
        errorService: false,
        flujo: {},
        isDigital: true,
        isLowCost: false,
        signFirst: false,
        colorScore: '',
        creditApplicationId: '',
        dispersion: CONSTANTS.SPEI
    })

    const TextHeader = styled.div`
        color: primary;
        text-align: center;
        font-weight: bold;
        font-size: 2em;
    `

    const buttonCss = css`
        display: flex;
        justify-content: flex-center;
    `

    const backDropCss = css`
        z-index: 1000;
    `

    const ImageContainer = styled.div`
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        width: 450;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    `

    const saveGtmcolor = (color) => {
        let puntaje
        switch (color) {
            case 'BLUE': puntaje = '950'; break;
            case 'GREEN': puntaje = '700'; break;
            case 'YELLOW': puntaje = '400'; break;
            case 'ORANGE': puntaje = '0'; break;
            case 'RED': puntaje = '0'; break;
            default: puntaje = '0'; break;
        }
        return puntaje
    }

    async function submit() {
        setState(prev => ({ ...prev, loading: true, buttonEnabled: false }))

        let clabe = '411111111111111111' // Default clabe for PreOfertaPrimerChamba

        try {
            const storedFlujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
            Offer = new OfferService(storedFlujo.creditApplicationId)

            // Set basic offer parameters
            Offer.setOfferId(state.datos.offerId)
            Offer.setDisbursementMethod(state.dispersion)
            Offer.setAcceptedInsuranceIds([])

            if (state.dispersion === CONSTANTS.SPEI || state.dispersion === CONSTANTS.SPEI2) {
                Offer.setClabe(clabe.length === 18 ? clabe : '')
                Offer.setCardNumber(clabe.length !== 18 ? clabe : '')
            }

            await Offer.send()

            sessionStorage.setItem('flujo',
                JSON.stringify({
                    ...storedFlujo,
                    preOferta: {
                        oferta: state.datos
                    }
                }))

            // Navigation logic
            if (state.isDigital) {
                if (state.signFirst || !state.isLowCost) {
                    if (state.dispersion === "Sucursal") {
                        navigate(`../muchas-gracias`)
                    } else {
                        navigate(`${pageContext.next.path}`)
                    }
                } else {
                    if (state.dispersion === "Sucursal") {
                        navigate(`../muchas-gracias`)
                    } else {
                        navigate(`${pageContext.next.path}`)
                    }
                }
            } else {
                navigate(pageContext.alternatePath)
            }

            // DataLayer pushes
            window.dataLayer = window.dataLayer || []
            dataLayer.push({
                'event': 'verPreOfertaSubmit',
                'eventCategory': 'Renovaciones',
                'eventAction': 'step1-clicContinuar',
                'eventLabel': `${state.datos.amount}_${state.datos.estimatedPayment}_${state.datos.terms}_${state.datos.period}`,
                'metodoPago': state.dispersion,
                'creditAppID': state.creditApplicationId
            })

            const valorcolor = saveGtmcolor(state.colorScore)
            dataLayer.push({
                'event': 'score',
                'color': `${state.colorScore}`,
                'valorColor': `${valorcolor}`
            })

        } catch (e) {
            setState(prev => ({ ...prev, errorService: true }))
            console.error('Error in submit:', e)
        } finally {
            setState(prev => ({ ...prev, buttonEnabled: true, loading: false }))
        }
    }

    async function fetchData(flujo) {
        try {
            Offer = new OfferService(flujo.creditApplicationId)

            // Obtener datos de preaprobación y oferta en paralelo
            const [resPreapproved, resOffer] = await Promise.all([
                catalogos.getPreapproved(flujo.creditApplicationId),
                Offer.getOffer()
            ])
            console.log({ resOffer });


            // Extraer el id de la primera oferta
            const offerId = resOffer.data.offers && resOffer.data.offers.length > 0 ?
                resOffer.data.offers[0].id : ''

            setState(prev => ({
                ...prev,
                datos: {
                    ...resPreapproved.data,
                    offerId
                },
                creditApplicationId: flujo.creditApplicationId,
                isDigital: resOffer.data.isDigital,
                isLowCost: resOffer.data.isLowCost,
                signFirst: resOffer.data.signFirst,
                dispersion: resOffer.data.allowedDisbursementMethods?.length > 0 ?
                    resOffer.data.allowedDisbursementMethods[0] : CONSTANTS.SPEI
            }))

            if (resPreapproved.data.forceRedirect !== null && resPreapproved.data.forceRedirect !== undefined) {
                navigate(resPreapproved.data.forceRedirect)
            }

            // Guardar flujo en sessionStorage
            const updatedFlujo = {
                ...flujo,
                datosAdicionales: {
                    ...flujo.datosAdicionales,
                    ...resOffer.data
                }
            }
            sessionStorage.setItem('flujo', JSON.stringify(updatedFlujo))

            // Push del evento verPreOferta
            window.dataLayer = window.dataLayer || []
            dataLayer.push({
                'event': 'verPreOferta',
                'eventCategory': 'RenovacionesDigital',
                'eventAction': 'verPreOfertaOnline',
                'eventLabel': undefined,
                'creditAppID': flujo.creditApplicationId
            })

        } catch (err) {
            console.error("Error in fetchData:", err)
            setState(prev => ({ ...prev, errorService: true }))
        }
    }

    async function colorScore(flujo) {
        try {
            const response = await catalogos.customer(pageContext.tenant, flujo.creditApplicationId)
            const { colorScore } = response.data

            if (colorScore !== null) {
                setState(prev => ({ ...prev, colorScore }))
                const valorcolor = saveGtmcolor(colorScore)
                window.dataLayer = window.dataLayer || []
                dataLayer.push({
                    'event': 'score',
                    'color': `${colorScore}`,
                    'valorColor': `${valorcolor}`
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        setState(prev => ({ ...prev, flujo }))
        fetchData(flujo)
        colorScore(flujo)

        return () => {
            // Cleanup
            sessionStorage.removeItem('bcScore')
            sessionStorage.removeItem('cotizador')
        }
    }, [])

    const props = [
        { icono: ArrowForwardIcon, titulo: 'Monto:', texto: `${formatNumber(state.datos.amount)}` },
        { icono: ArrowForwardIcon, titulo: 'Pago:', texto: `${formatNumber(state.datos.estimatedPayment)}` },
        { icono: ArrowForwardIcon, titulo: 'Plazo:', texto: `${state.datos.terms} ${getPeriodPlural(state.datos.period)} ` }
    ]

    return (
        <div>
            <Backdrop open={state.loading} css={backDropCss}>
                <CircularProgress color="primary" />
            </Backdrop>

            <Snackbar
                autoHideDuration={6000}
                onClose={() => setState(prev => ({ ...prev, errorService: false }))}
                open={state.errorService}
            >
                <Alert
                    onClose={() => setState(prev => ({ ...prev, errorService: false }))}
                    severity="warning"
                    variant="filled"
                >
                    Lo sentimos, por el momento el servicio no se encuentra disponible
                </Alert>
            </Snackbar>

            <Grid container space={2} justify="center">
                <Grid item xs={12} sm={10} md={8}>
                    <Grid container justify="center">
                        <br />
                        <Typography color='primary'>
                            <TextHeader>¡Felicidades! Tienes una pre-oferta, estás a unos pasos de obtener tu crédito.</TextHeader>
                            <br />
                        </Typography>
                        <Grid item xs={12} sm={6}>
                            <br />

                            <Typography css={css`padding: 1rem;`}>
                                De acuerdo con la información que nos has proporcionado "{NameCompany(pageContext.company).display}"  tiene una oferta pre-aprobada para ti.
                                <br />
                                <br />
                                <Oferta datos={props} colorOne={colorOne} colorTwo={colorTwo} />
                                <br />
                                <Typography>
                                    Estamos validando los datos proporcionados ¡Gracias!
                                </Typography>
                                <br />
                            </Typography>
                            <br />
                            <Grid container justify="center">
                                <Grid item>
                                    <div css={buttonCss} >
                                        <ButtonDegrade
                                            onClick={submit}
                                            disabled={!state.buttonEnabled}
                                            textButton={state.loading ? <CircularProgress size={30} color="primary" /> : "Continuar"}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <ImageContainer>
                                <CarouselImg filename="1.svg" company={pageContext.company} />
                            </ImageContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

PreOfertaPrimerChamba.propTypes = {
    pageContext: PropTypes.object.isRequired
}

export default PreOfertaPrimerChamba